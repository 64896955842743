<template>
  <div class="sidebar">
    <div class="sidebar__header">
      <button class="sidebar__close-button" @click="closeSidebar">
        <v-icon-cross />
      </button>
      <div class="sidebar__user-data">
        <v-header-user-data />
      </div>
    </div>
    <div class="sidebar__logo">
      <v-logo />
    </div>
    <div class="sidebar__user">
      <div class="sidebar__profile-data">
        <v-avatar class="sidebar__avatar" :src="avatarSrc" size="medium" />
        <div class="sidebar__username">{{ shortName }}</div>
      </div>
      <router-link v-if="role !== $options.ROLE_OWNER" :to="{ name: 'notifications' }" class="sidebar__notifications">
        <span
          class="sidebar__notifications-badge"
          :class="{ 'sidebar__notifications-badge--not-active': !unreadNotificationsCount }"
          >{{ unreadNotificationsCount }}</span
        >
      </router-link>
    </div>
    <div class="sidebar__menu-wrapper">
      <ul class="sidebar__menu">
        <router-link
          v-for="(item, index) in menuItems"
          :key="index"
          v-slot="{ href, navigate, isActive }"
          :to="item.to"
          custom
        >
          <li
            v-if="!hideMenuItem(item)"
            :class="['sidebar__item', 'sidebar__item--menu', isActive && 'sidebar__item--active']"
            @click=";(!isActive || $route.name !== item.to.name) && navigate($event)"
          >
            <a class="sidebar__link" :href="!isActive && href">{{ item.label }}</a>
            <span
              v-if="item.withCounter"
              class="sidebar__notifications-badge"
              :class="{ 'sidebar__notifications-badge--not-active': !expiredRemindersCount }"
              >{{ expiredRemindersCount }}</span
            >
          </li>
        </router-link>
      </ul>
      <v-button-wide class="sidebar__item sidebar__item--logout" @click="userLogout">Выйти</v-button-wide>
    </div>
  </div>
</template>

<script>
import VLogo from '@/components/common/VLogo.vue'
import VAvatar from '@/components/common/VAvatar.vue'
import VHeaderUserData from '@/components/common/VHeaderUserData.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VIconCross from '@/components/icons/VCross.vue'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { MODULE_NOTIFICATIONS } from '@/store/modules/notifications/notifications.types'
import { mapGetters, mapState } from 'vuex'
import { shortifyName } from '@/utils/common'
import { ROLE_ADMIN, ROLE_AGENT, ROLE_OWNER } from '@/constants/roles'
import { LOGOUT_ROUTE_NAME } from '@/constants/routes'
import { MODULE_REMINDERS } from '@/store/modules/reminders/reminders.types'

export default {
  ROLE_OWNER,
  name: 'TheSidebar',
  components: { VLogo, VIconCross, VHeaderUserData, VAvatar, VButtonWide },
  data() {
    return {
      menuItemsForAdmin: [
        { label: 'Аналитика', to: { name: 'dashboard' } },
        { label: 'Объекты недвижимости', to: { name: 'objects-realty' } },
        { label: 'Пользователи', to: { name: 'users' } },
        { label: 'Собственники', to: { name: 'owners' } },
        { label: 'Покупатели', to: { name: 'customers' } },
        { label: 'Лиды', to: { name: 'calls' } },
        { label: 'Показы', to: { name: 'shows' } },
        { label: 'Напоминания', to: { name: 'reminders' }, withCounter: true },
        { label: 'Коттеджные поселки', to: { name: 'villages' }, hiddenOnNmh: true },
        { label: 'Блог', to: { name: 'articles' }, hiddenOnNmh: true }
      ],

      menuItemsForAgent: [
        { label: 'Аналитика', to: { name: 'dashboard' } },
        { label: 'Объекты недвижимости', to: { name: 'objects-realty' } },
        { label: 'Собственники', to: { name: 'owners' } },
        { label: 'Покупатели', to: { name: 'customers' } },
        { label: 'Лиды', to: { name: 'calls' } },
        { label: 'Показы', to: { name: 'shows' } },
        { label: 'Напоминания', to: { name: 'reminders' }, withCounter: true }
      ],

      menuItemsForOwner: [{ label: 'Объекты недвижимости', to: { name: 'objects-realty' } }]
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    ...mapState({
      username: state => state[MODULE_SESSION].model.name,
      avatarSrc: state => state[MODULE_SESSION].model.photo,
      role: state => state[MODULE_SESSION].model.role,
      unreadNotificationsCount: state => state[MODULE_NOTIFICATIONS].unreadCount,
      expiredRemindersCount: state => state[MODULE_REMINDERS].expiredCount
    }),
    shortName() {
      return shortifyName(this.username)
    },
    menuItems() {
      if (this.role === ROLE_ADMIN) return this.menuItemsForAdmin
      if (this.role === ROLE_AGENT) return this.menuItemsForAgent
      return this.menuItemsForOwner
    }
  },
  methods: {
    hideMenuItem(item) {
      return !this.isNmh && item.hiddenOnNmh
    },
    userLogout() {
      this.$router.push({ name: LOGOUT_ROUTE_NAME })
    },
    closeSidebar() {
      this.$emit('close')
    }
  }
}
</script>
