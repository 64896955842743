<template>
  <svg class="icon icon--burger" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 8V6h18v2H3zm0 5h18v-2H3v2zm0 5h18v-2H3v2z" fill="#282829" />
  </svg>
</template>

<script>
export default {
  name: 'VBurger'
}
</script>
