<template>
  <div class="header-user-data">
    <div class="header-user-data__name">{{ username }}</div>
    <div class="header-user-data__email">{{ email }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default {
  name: 'VHeaderUserData',
  computed: {
    ...mapState(MODULE_SESSION, {
      username: state => state.model.name,
      email: state => state.model.email
    })
  }
}
</script>
